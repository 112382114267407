import React, { useEffect } from "react";
import styled from "styled-components";

import AuthUserContext from "components/Session/AuthUserContext";
import withAuthorization from "components/Session/withAuthorization";

import {
  Container,
  Header,
  Next,
} from "components/Onboarding/OnboardingComponents";

function Hello() {
  

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <Container>
          <Header />
          <section>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Hello
                {authUser.displayName &&
                  ", " + authUser.displayName.split(" ")[0]}
              </h1>
              <Next
                paintDrip
                to="/onboarding/questions/interest"
                hex="#438cee"
                duration={1}
              >
                Okay, let's do this &#8250;
              </Next>
            </div>
          </section>
        </Container>
      )}
    </AuthUserContext.Consumer>
  );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Hello);
